@import "~cwb-react/dist/cwb-react.css";
/* @import "~video.js/dist/video-js.css"; */

@font-face {
  font-family: "icomoon";
  src: local("icomoon"), url("~cwb-react/dist/fonts/cwb.eot?cx8vv2");
  src: local("icomoon"),
    url("~cwb-react/dist/fonts/cwb.eot?cx8vv2#iefix") format("embedded-opentype"),
    url("~cwb-react/dist/fonts/cwb.ttf?cx8vv2") format("truetype"),
    url("~cwb-react/dist/fonts/cwb.woff?cx8vv2") format("woff"),
    url("~cwb-react/dist/fonts/cwb.svg?cx8vv2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #edf0f6 !important;
}

label.light-green,
span.light-green {
  color: #1f8651;
  font-weight: 600;
}

span.red {
  color: red;
  font-weight: 600;
}

span.orange {
  color: orange;
  font-weight: 600;
}

.back {
  position: relative;
  top: -13%;
  left: -65%;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}

.back2 {
  position: relative;
  top: -63px;
  left: -417px;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}

.spinner-width {
  width: 10% !important;
}

.form-control {
  display: block;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.StripeElement {
  width: 500px;
}

.StripeElement--focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}

.__PrivateStripeElement {
  top: 3px !important;
}

.icon-cwb-unchecked,
.icon-cwb-unchecked:before,
.icon-cwb-checked,
.icon-cwb-checked:before {
  font-size: 12px !important;
}

.safari-btn {
  padding-bottom: 30px !important;
}
